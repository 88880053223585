//----------------------------------------------------------------------------

var GameManagement = (function () {

    var settings = {
        class_id: null,
        exit_alert: false
    };

    var init = function () {

        bindUIActions();

        checkScreenSize();

        $(window).resize(function () {
            checkScreenSize();
        });

        fieldChangesDetect();

        $('#myClassCreate')
                .on('hide.bs.modal', function () {
                    $("#exit_link").unbind("click");
                    $("#exit_link").removeAttr("onclick");
                });
    };

    var bindUIActions = function () {

        //-- create new pupils:
        $('.add_pupils').on('click', function (event) {

            settings.class_id = $(this).data("class-id");
            $.ajax({
                url: "/islogged",
                type: 'GET',
                async: false,
                cache: false,
            }).done(function (val) {
                if (val === "false") {
                    window.location = "/";
                } else {
                    addStudentsToClass();
                }
            }).fail(function () {
                window.location = "/";
            });

        });

        //-- delete pupils:
        $('.del_pupils').on('click', function (event) {
            event.stopPropagation();
            settings.class_id = $(this).data("class-id");
            $.ajax({
                url: "/islogged",
                type: 'GET',
                async: false,
                cache: false,
            }).done(function (val) {
                if (val === "false") {
                    window.location = "/";
                } else {
                    deleteStudentsFromClass();
                }
            }).fail(function () {
                window.location = "/";
            });
        });

        //-- transfer selected students:
        $('.transfer_pupils').on('click', function (event) {
            event.preventDefault();
            settings.class_id = $(this).data("class-id");
            $.ajax({
                url: "/islogged",
                type: 'GET',
                async: false,
                cache: false,
            }).done(function (val) {
                if (val === "false") {
                    window.location = "/";
                } else {
                    transferStudentsFromClass();
                }
            }).fail(function () {
                window.location = "/";
            });
        });

        //-- transfer all students:
        $('.transfer_all_class').on('click', function (event) {
            event.preventDefault();
            settings.class_id = $(this).data("class-id");
            $.ajax({
                url: "/islogged",
                type: 'GET',
                async: false,
                cache: false,
            }).done(function (val) {
                if (val === "false") {
                    window.location = "/";
                } else {
                    transferAllStudentsFromClass();
                }
            }).fail(function () {
                window.location = "/";
            });
        });

        //-- delete all students:
        $('.delete_all_class').on('click', function (event) {
            event.preventDefault();
            settings.class_id = $(this).data("class-id");
            $.ajax({
                url: "/islogged",
                type: 'GET',
                async: false,
                cache: false,
            }).done(function (val) {
                if (val === "false") {
                    window.location = "/";
                } else {
                    deleteClass();
                }
            }).fail(function () {
                window.location = "/";
            });
        });

        //--- save pupil stuff:
        $('.save_pupil_data').on('click', function (event) {
            $("#class_form_" + $(this).data("class-id")).submit();
        });

        $('.class_form').on('click', function (event) {
            event.stopPropagation();
        });

        //-- open and close class information:
        $(".class_toogle").on('click', function (event) {
            event.stopPropagation();
            var elem = $(this).parent().parent().parent().next();
            var class_id = $(this).data("class-id");

            if (elem.is(':hidden'))
            {
                $(this).attr('class', 'fa fa-chevron-down pull-left class_toogle');
                $(this).attr('style', 'margin-left: -7px !important;');
                elem.show();

                //-- reset selected radios:
                $('.all_pupils_from_' + class_id).prop('checked', false);
                $('.pupil_class_' + class_id).prop('checked', false);
            }
            else
            {
                $(this).attr('class', 'fa fa-chevron-right pull-left class_toogle');
                $(this).removeAttr('style');
                elem.hide();
            }
        });

        $('[class^="pupil_class"]').on('click', function () {
            selectStudent(this);
        });

        $('.class_field').on('click', function (event) {
            event.stopPropagation();
        });

        $('[class^="all_pupils_from_"]').on('click', function () {
            selectAllStudents(this);
        });

        $('.class_field').on('focus', function (event) {
            $(this).val($(this).data("class-name"));
        });

        $('.class_field').on('blur', function (event) {
            $("#class_name_" + $(this).data("class-custom-id")).val($(this).val());
            $(this).val($(this).val() + " (ID:" + $(this).data("class-custom-id") + ")");
        });

        $('.pupils_download').on('click', function () {
            window.location = "/pupils/download/" + $(this).data("class-id");
        });

        $('.exit_confirm').on('click', function (event) {
            event.stopPropagation();
            exitConfirm($(this).data("link"));
        });
    };

    var addStudentsToClass = function (event) {

        if (settings.exit_alert === true) {
            $('#myGameManExit').modal('show');
            $('#exit_link').on('click', function (event) {
                $('#myGameManExit').modal('hide');
                $('#myAddStudents .modal-content').load('/pupils/create/' + settings.class_id);
                $('#myAddStudents').modal('show');
                $("#exit_link").unbind("click");
            });
        } else {
            $('#myAddStudents .modal-content').load('/pupils/create/' + settings.class_id);
            $('#myAddStudents').modal('show');
        }
    };

    var deleteStudentsFromClass = function (event) {

        if (settings.exit_alert === true) {

            $('#exit_link').on('click', function (e) {
                $('#myGameManExit').modal('hide');
                $('#myDelStudents .modal-content').load('/pupils/delete/' + settings.class_id);
                $('#myDelStudents').modal('show');
                $("#exit_link").unbind("click");
            });
            $('#myGameManExit').modal('show');
        } else {
            $('#myDelStudents .modal-content').load('/pupils/delete/' + settings.class_id);
            $('#myDelStudents').modal('show');
        }

    };

    var deleteClass = function () {
        if (settings.exit_alert === true) {
            $("#exit_link").unbind("click");
            $('#exit_link').on('click', function (e) {
                $('#myGameManExit').modal('hide');
                $('#myDeleteClass .modal-content').load('/classes/delete/' + settings.class_id);
                $('#myDeleteClass').modal('show');
                $("#exit_link").unbind("click");
            });
            $('#myGameManExit').modal('show');
        } else {
            $('#myDeleteClass .modal-content').load('/classes/delete/' + settings.class_id);
            $('#myDeleteClass').modal('show');
        }
    };

    var transferStudentsFromClass = function () {
        if (settings.exit_alert === true) {
            $('#myGameManExit').modal('show');
            $('#exit_link').on('click', function (event) {
                $('#myGameManExit').modal('hide');
                $('#myTransferStudents .modal-content').load('/pupils/transfer/' + settings.class_id);
                $('#myTransferStudents').modal('show');
                $("#exit_link").unbind("click");
            });
        } else {
            $('#myTransferStudents .modal-content').load('/pupils/transfer/' + settings.class_id);
            $('#myTransferStudents').modal('show');
        }
    };

    var transferAllStudentsFromClass = function () {
        if (settings.exit_alert === true) {
            $('#myGameManExit').modal('show');
            $('#exit_link').on('click', function (event) {
                $('#myGameManExit').modal('hide');
                $('#myTransferAllStudentsClass .modal-content').load('/classes/transfer/' + settings.class_id);
                $('#myTransferAllStudentsClass').modal('show');
                $("#exit_link").unbind("click");
            });
        } else {
            $('#myTransferAllStudentsClass .modal-content').load('/classes/transfer/' + settings.class_id);
            $('#myTransferAllStudentsClass').modal('show');
        }
    };

    var selectAllStudents = function (elem) {

        var all_checked = $(elem).is(':checked');
        if (all_checked === true) {

            $('.pupil_class_' + $(elem).data("class-id")).prop('checked', true);

        } else {

            $('.pupil_class_' + $(elem).data("class-id")).prop('checked', false);

        }
    };

    var selectStudent = function (elem) {

        var is_checked = $(elem).is(':checked');
        if (is_checked === true) {

            $(is_checked).prop('checked', true);
        } else {

            $(is_checked).prop('checked', false);
        }

        if ($('.pupil_class_' + $(elem).data("class-id") + ':checked').length
                === $('.pupil_class_' + $(elem).data("class-id")).length) {

            $('.all_pupils_from_' + $(elem).data("class-id")).prop('checked', true);
        } else {

            $('.all_pupils_from_' + $(elem).data("class-id")).prop('checked', false);
        }
    };

    var checkScreenSize = function () {
        if ($(window).width() <= 766) {
            $(".desktop_field").attr("disabled", true);
            $(".mobile_field").attr("disabled", false);
        } else {
            $(".desktop_field").attr("disabled", false);
            $(".mobile_field").attr("disabled", true);
        }
    };

    var fieldChangesDetect = function () {

        $("input:not([type='checkbox']).desktop_field").on('change', function () {
            settings.exit_alert = true;
        });

        $("input:not([type='checkbox']).class_field").on('change', function () {
            settings.exit_alert = true;
        });
    };

    var exitConfirm = function (link) {

        if (settings.exit_alert === true) {

            if (link == "#create_class") {
                $('#exit_link').attr("onclick", "$('#create_class').click();$('#myGameManExit').modal('hide');");
            } else {
                $('#exit_link').attr("onclick", "window.location ='" + link + "'");
            }

            $('#myGameManExit').modal('show');

        } else {

            if (link == "#create_class") {

                $(link).click();

            } else {

                window.location = link;
            }

        }
    };

    return {
        init: init
    };

})();

$(document).ready(function () {
    // routemap zoom plugin
    var $section = $('#inverted-contain');
    $section.find('.panzoom').panzoom({
        $zoomIn: $section.find('.zoom-in'),
        $zoomOut: $section.find('.zoom-out'),
        startTransform: 'scale(0)',
        increment: 0.1,
        minScale: 1,
        contain: 'invert'
    }).panzoom('zoom');

    // bind touch and click for zoom buttons
    $('.buttons > button', $section).on('touchstart click', function() {
        var element = $(this),
            isZoomIn = element.hasClass('zoom-in'),
            opposite = isZoomIn ? $('.zoom-out') : $('.zoom-in'),
            currentZoom = parseFloat($section.find('.panzoom').panzoom('getMatrix')[0]);

        // remove the focused from the opposite and add to the current one
        opposite.removeClass('focused');

        if ((isZoomIn && currentZoom >= 5) ||
                (!isZoomIn && currentZoom <= 1)) {
            element.removeClass('focused');
        } else {
            element.addClass('focused');
        }
    });

    $('.teacher-private-page').click(function(event) {
        if (teacherGateway(event)) {
            window.location = extractLocation(this);
        }
    });

    // document download validation according user session status
    $('.teacher-download').click(function (event) {
        // create the event for analytics
        var filename = filenameFromElement(this),
            currentUrl = window.location.href;

        if (teacherGateway(event)) {
            // yes! we can proceed
            analyticsEvent('button', 'click', 'authenticated_download_' + filename + '_' + currentUrl);
            window.location = extractLocation(this);
        } else {
            // add to analytics the event
            analyticsEvent('button', 'click', filename + '_anonymous_click_' + currentUrl);
        }
    });

    // play game tracking
    $('.play-game').click(function () {
      var page = this.tagName == 'A' ? window.pageId : $(this).data('page'),
          link = this.tagName == 'A' ? this.href : '/game/';
          analyticsEvent('button', 'click', 'play_game_' + page);

    });

    // registration tracking
    $('#teacher-register').click(function () {
        analyticsEvent('button', 'click', 'register');
    });

    /* cookie policy */
    $.cookieBar({
        element: '.header',
        message: 'This site uses cookies. By continuing to use this site you agree to our use of cookies. <a href="/cookies">read more</a>'
    });

    //-- modal design fixes
    $('#myForgotenPass')
            .on('show.bs.modal', function (e) {
                $('#myLogin').modal('hide');
                $('body').attr("style", "padding-right:0px !important; overflow-y: scroll;");
            });

    //-- modal design fixes
    $('#myPoi')
            .on('show.bs.modal', function (e) {
                $('body').attr("style", "padding-right:0px !important; overflow-y: scroll;");

            });

    $('#myLogin')
            .on('show.bs.modal', function (e) {
                $('body').attr("style", "position:fixed !important; width:100%;");
            });

    $('#myLogin')
            .on('loaded.bs.modal', function(e) {
                // bind login form submit
                $('#myLogin #login_form').submit(function(event) {
                    event.preventDefault();
                    // on valid do the thing
                    var form = $(this);
                    if (form.valid()) {
                        $.ajax({
                            url: form.attr('action'),
                            type: form.attr('method'),
                            dataType: 'json',
                            data: form.serialize()
                        }).done(function(response) {
                          window.location = '/' + response.path;
                        }).fail(function(request) {
                            // we will output the error on the field and show
                            // the error message
                            var message = request.responseJSON.message,
                                form = $('#login_form'),
                                element,
                                field = Object.keys(message)[0]; // only exists one

                            $('.form-error', form).remove();
                            // show error
                            element = $('<span class="form-error">' + message[field] + '</span>');
                            form.prepend(element.hide().fadeIn());

                            // highlight field
                            $('input[name=' + field + ']', form).addClass('has-error');
                        });
                    }
                });
            });

    $('#myLogin')
            .on('hide.bs.modal', function (e) {
                $('body').attr("style", "position:static !important; width:100%;");

                // check if sessionStorage is available and clear sessionStorage
                if (window.sessionStorage) {
                    sessionStorage.clear();
                }
            });


    //-- login modal load:
    $('#login_btn').click(function (e) {
        $('#myLogin .modal-body').load(this.href);
    });

    // bind mouse over and out on home page
    $('.ticket-div').mouseover(function (e) {
        $('.ticket-div').not(this).addClass('blurred');
    }).mouseout(function (e) {
        $('.ticket-div').removeClass('blurred');
    });

    /*****************************************************************/
    /* REGISTER PAGE - trigger on click to search school by postcode */
    /*****************************************************************/
    //$("#search_school").click(function () {
    $('.school-search').click(function () {

        if ($("#postcode").val() === "") {
            return;
        }

        /* remove possible postcode messages already displayed */
        $("#address_not_found_div").remove();

        /* inform user that we are searching on db */
        $('<div id="searching_school">searching..</div>').insertAfter("#search_school");

        /* block btn to avoid many requests..*/
        $("#search_school").attr("disabled", "disabled");

        $.get("/searchschool", {criteria: $("#postcode").val()}, function () {
        }).done(function (result) {

            //-- reset school table list (not in use for now):
            $('#schools_table').find('tbody').empty();
            $('#school-details').slideDown();
            $('#teacher-register').fadeIn();

            //-- parse json result:
            var schools = $.parseJSON(result);

            //-- if search return just one result, apply directly:
            if (schools.length == 0) {

                //-- show info to client according design spec:
                $('<div id="address_not_found_div" class="form-group form_error_messages"><i class="fa fa-times-circle"></i> ' +
                        '<span>No address found, please enter your school\'s address. </span></div>')
                        //.insertAfter("#school_postcode_search_btn");
                        .insertAfter('.school-search');

                //-- clean school fields:
                resetSchoolsFields();

                return;
            }
            else if (schools.length == 1) {

                //-- populate schools fields:
                populateSchoolsFields(schools);

            } else {

                //-- otherwise let user choose the school
                $(schools).each(function (k, v) {

                    //-- create a new row obj;
                    var row = $('<tr/>');

                    //-- append it on output:
                    $('#schools_table').find('tbody').append(row);

                    //-- add school information columns:
                    $('<td/>', {text: v.id}).appendTo(row);
                    $('<td/>', {text: v.name}).appendTo(row);
                    $('<td/>', {text: v.postcode}).appendTo(row);
                });

                //-- at the end show list to client:
                $('#schools_list').modal('show');
            }

        }).fail(function () {
            alert("error");
            $("#search_school").removeAttr("disabled");
            $("#searching_school").remove();

        }).always(function () {
            $("#search_school").removeAttr("disabled");
            $("#searching_school").remove();
        });
    });
    /*****************************************/
    /* REGISTER PAGE - setup for select2 fields */
    /*****************************************/
    $("#primary_position_select").select2({
        'allowClear': false
    });
    $("#secondary_position_select").select2({
        'allowClear': false
    });
    $("#country_select").select2({
        'allowClear': false
    });
    $("#phase_select").select2({
        'allowClear': false
    });
    $("#hear_about_select").select2({
        'allowClear': false
    });

    /*****************************************/
    /** REGISTER PAGE - Select option field setup **/
    /*****************************************/
    /* onload call function: */
    showOtherInstitutionField();
    /* on change call function: */
    $('#institution_selection').change(function () {

        resetSchoolsFields();
        showOtherInstitutionField();
    });

    /*****************************************/
    /** REGISTER PAGE - Primary position field setup **/
    /*****************************************/
    /* onload call function: */
    showOtherPrimaryPositionField();
    /* on change call function: */
    $('#primary_position_select').change(function () {
        showOtherPrimaryPositionField();
    });

    /*****************************************/
    /** REGISTER PAGE - Secondary position field setup**/
    /*****************************************/
    /* onload call function: */
    showOtherSecondaryPositionField();
    /* on change call function: */
    $('#secondary_position_select').change(function () {
        showOtherSecondaryPositionField();
    });

    /*****************************************/
    /* REGISTER PAGE - Setup jquery validation rules */
    /*****************************************/
    var icon = "<i class='fa fa-times-circle'></i>";
    $("#registration").validate({
        rules: {
            first_name: {
                required: true,
                minlength: 2
            },
            last_name: {
                required: true,
                minlength: 2
            },
            email: {
                required: true,
                email: true,
            },
            confirm_email: {
                required: true,
                email: true,
                equalTo: '#email'
            },
            password: {
                required: true,
            },
            confirm_password: {
                required: true,
                equalTo: '#password'
            },
            other_institution: {
                required: function () {
                    return $('input[name=institution_selection]:checked').val() === 'other';
                }
            },
            primary_position: {
                required: true
            },
            school_postcode: {
                required: true
            },
            school_name: {
                required: true
            },
            address1: {
                required: true
            },
            address2: {
                required: true
            },
            country: {
                required: true
            },
            phase: {
                required: true
            },
            hear_about: {
                required: false
            }
        },
        messages: {
            first_name: {
                required: icon + "<span>You have not entered a first name.</span>",
                minlength: icon + "<span>Your first name must be at least 2 characters long.</span>"
            },
            last_name: {
                required: icon + "<span>You have not entered a last name. </span>",
                minlength: icon + "<span>Your last name must be at least 2 characters long.</span>"
            },
            email: {
                required: icon + "<span>You have not entered an email.</span>",
                email: icon + "<span>You have not entered a valid email.</span>"
            },
            confirm_email: {
                required: icon + "<span>You have not entered the confirm email.</span>",
                equalTo: icon + "<span>Your email does not match the email above.</span>",
                email: icon + " <span>You have not entered a valid email.</span>"
            },
            password: {
                required: icon + "<span>You have not entered a password.</span>"
            },
            confirm_password: {
              required: icon + "<span>You have not entered a password.</span>",
              equalTo: icon + "<span>Your password does not match the password above.</span>",
            },
            other_institution: {
                required: icon + "<span>You have not entered other option description.</span>"
            },
            primary_position: {
                required: icon + "<span>You need to select a primary position.</span>"
            },
            school_postcode: {
                required: icon + "<span>You have not entered a school postcode.</span>"
            },
            school_name: {
                required: icon + "<span>You have not entered a school name.</span>"
            },
            address1: {
                required: icon + "<span>You have not entered Address 1.</span>"
            },
            address2: {
                required: icon + "<span>You have not entered Address 2.</span>"
            },
            country: {
                required: icon + "<span>You need to select a country.</span>"
            },
            phase: {
                required: icon + "<span>You need to select a phase.</span>"
            }
        },
        errorPlacement: function (error, element) {
            /* little fix here.. select2 error position ajustment.. jump 2 elements */
            if (element.is("select")) {
                error.insertAfter(element.next());
            } else {
                error.insertAfter(element);
            }
        },
        errorClass: 'form_error_messages',
        invalidHandler: function () {
            $("#error_message").text(" There are mandatory fields you have not completed");
            $('<i class="fa fa-times-circle"></i>').prependTo($(".form_error_messages"));
        }
    });

    /*****************************************/
    /* LOGIN MODAL - Setup jquery validation rules */
    /*****************************************/
    loginValidation();

    /*****************************************/
    /* RESET MODAL - Setup jquery validation rules */
    /*****************************************/
    resetValidation();

    //------------------- MOBILE ADJUSTS:
    $('#mobile-user-toggle, #mobile-main-toggle').on('touchstart click', function() {
        var target = this.id == 'mobile-user-toggle' ? 'mobile-main-toggle' : 'mobile-user-toggle';
        closeMenu('#' + target);
    });

    function closeMenu(target) {
        target = $(target);
        // we only do this if the target is open
        if (!target.hasClass('collapsed')) {
            target.click();
        }
    }

    /***********************************/
    // PERFORMANCE PAGE
    /***********************************/

    //Initialising Accordion
    $(".accordion").tabs($(".pane"), {
        tabs: '> label',
        effect: 'slide',
        initialIndex: null
    });

    //The click to hide function
    $(".accordion > label").click(function () {

        if ($(this).hasClass("current") && $(this).next().queue().length === 0) {

            $(this).next().slideUp();
            $(this).removeClass("current");

            if ($(this).parent().hasClass("first_level")) {
                $(this).find(".fa-chevron-right").show();
                $(this).find(".fa-chevron-down").hide();
            }

            if ($(this).parent().hasClass("second_level")) {
                $(this).find(".fa-plus-circle").show();
                $(this).find(".fa-minus-circle").hide();
            }

            if ($(this).parent().hasClass("third_level")) {
                $(this).find(".fa-chevron-right").show();
                $(this).find(".fa-chevron-down").hide();
            }

        } else if (!$(this).hasClass("current") && $(this).next().queue().length === 0) {

            $(this).next().slideDown();
            $(this).addClass("current");

            if ($(this).parent().hasClass("first_level")) {
                $(this).find(".fa-chevron-right").hide();
                $(this).find(".fa-chevron-down").show();
            }

            if ($(this).parent().hasClass("second_level")) {

                $(this).find(".fa-plus-circle").hide();
                $(this).find(".fa-minus-circle").show();
                if ($(this).hasClass('students_level') && $(this).next().next().hasClass('current')) {
                    $(this).next().next().click();
                }

                if ($(this).hasClass('challenges_level') && $(this).prev().prev().hasClass('current')) {
                    $(this).prev().prev().click();
                }
            }

            if ($(this).parent().hasClass("third_level")) {

                $(this).find(".fa-chevron-right").hide();
                $(this).find(".fa-chevron-down").show();
            }
        }
    });
});

/******************************************************/
/* REGISTER PAGE - function containing the logical for "other option" */
/******************************************************/
function showOtherPrimaryPositionField() {
    if ($('#primary_position_select').val() === 'other') {
        $("#other_primary_position_field").fadeIn(1000);
    } else {
        $("#other_primary_position_field").hide();
    }
}

/******************************************************/
/* REGISTER PAGE - function containing the logical for "other option" */
/******************************************************/
function showOtherSecondaryPositionField() {
    if ($('#secondary_position_select').val() === 'other') {
        $("#other_secondary_position_field").fadeIn(1000);
    } else {
        $("#other_secondary_position_field").hide();
    }
}

/**************************************************************/
/* REGISTER PAGE - function to populate school fields with school information */
/**************************************************************/
function populateSchoolsFields(school) {
    $("#input_school_name").val(school[0].name);
    $("#input_school_address1").val(school[0].address1);
    $("#input_school_address2").val(school[0].address2);
    $('#country_select').select2('val', school[0].country);
    $('#phase_select').select2('val', school[0].phase);
}

/**************************************************************/
/* REGISTER PAGE - function to clean school fields */
/**************************************************************/
function resetSchoolsFields() {
    $("#input_school_name").val("");
    $("#input_school_address1").val("");
    $("#input_school_address2").val("");
    $('#country_select').select2('val', "");
    $('#phase_select').select2('val', "");
}

/**********************************************************************/
/* REGISTER PAGE - function containing the logical for "other option" */
/**********************************************************************/
function showOtherInstitutionField() {

    if ($('input[name=institution_selection]:checked').val() === 'other') {

        /* show other fields */
        $("#other_institution_textarea").show();

        /* hide school_postcode name field */
        $("#school_postcode").hide();

        /* hide school search name btn */
        // $("#school_postcode_search_btn").hide();
        $('.school-search').hide();

        /* hide school name field */
        $("#school_name").hide();

        /* show school details right now */
        $("#school-details").slideDown();
        $('#teacher-register').fadeIn();

    } else {

        /* hide other fields */
        $("#other_institution_textarea").hide();

        /* show school_postcode name field */
        $("#school_postcode").show();

        /* show school search name btn */
        /// $("#school_postcode_search_btn").show();
        $('.school-search').show();

        /* show school name field */
        $("#school_name").show();

        if ($('#input_school_name').val() == '') {
          $('#school-details').slideUp();
          $('#teacher-register').fadeOut();
        }
    }
}

/******************************************************/
/* DAYSOUT / SCHOOLTRIPS
 /******************************************************/
function download(url, file_name, file_page) {
    newwindow = window.open(url);
    if (window.focus) {
        analyticsEvent('button', 'click', file_page + '_ ' + file_name);
        newwindow.location = url;
    }
    return false;
}

/******************************************************/
/* BIND COMMON EVENTS */
/******************************************************/
$(document).ready(function() {
    $('[data-back]').on('click', function(event) {
        var element = $(this);

        // prevent any default behavior
        event.preventDefault();

        // start by check the value of data-back, if is undefined, check if
        // and attribute href is available, otherwise ... just do a history.back()
        var destination = element.data('back');
        if (destination === undefined) {
            if (element.attr('href') !== undefined) {
                destination = element.attr('href');
            } else {
                history.back();
                return;
            }
        }
        // go to location
        window.location = destination;
    });

    $('.guide-help').on('click', function(event) {
        event.preventDefault();
        var element = $(this),
            target = $(element.data('target'));
        if (!target.data('loaded')) {
            $('.modal-content', target).load(element.attr('href'), function() {
                target.data('loaded', true);
            });
        }
        target.modal('show');
    });

    // protected bind
    $('[data-protected]').on('click', function(event) {
        teacherGateway(event);
    });
});

/******************************************************/
/* MOBILE MENU SETUP */
/******************************************************/
$(document).ready(function() {
  var menu = $('.mobile-navigation .user-navigation'),
      pathname = window.location.pathname;

  // so we need to loop each menu that has a subnav, duplicate the current
  // page inside of it, add an expander to the top nav (and bind event),
  // then we need to add a back button
  $('.subnav', menu).each(function(index, element) {
      var parent,
          clone,
          liElement = $('<li />');
      element = $(element);

      parent = element.parent();
      parent.addClass('has-subnav');

      // so we clone the main option and add it to the subnav
      clone = $('> a', parent).clone();
      clone.text(clone.text() + ' page');

      // check if this is really the page where we are
      if (pathname == clone.attr('href')) {
          clone.addClass('current');
      }

      liElement.append(clone);
      element.prepend(liElement);

      // now we add a back button
      element.prepend('<li><a href="' + clone.attr('href') + '" class="back-button">Back</a></li>');
  });

  $('.has-subnav > a').on('click', function(event) {
      event.preventDefault();
      var parent = $(this).parents('li'),
          subnav;
      // let's clone the subnav
      subnav = $('.subnav', parent).clone();
      // get the parent and append the subnav
      $('.user-navigation')
        .parent()
        .append(subnav)
        .addClass('in');
  });

  // bind all back buttons to return to the last menu
  $(document).on('click', '.subnav .back-button', function(event) {
      event.preventDefault();
      var target = $(this).parents('.side-by-side'),
          transitionEvents = 'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend';
      target.removeClass('in');
      // now we use a listener to remove the menu after the transition ends
      target.one(transitionEvents, function() {
          // remove the last children
          target.children()
                .last()
                .remove();
          // now we need to remove the listener
          target.off(transitionEvents);
      });
  });
});

$(document).ready(function() {
    // check if we need to do some action, only if sessionStorage is available
    if (window.sessionStorage && sessionStorage.getItem('action')) {
        // yes, this should be a link, but we try to grab the element that
        // as that href to trigger the click to pass through javascript
        $('a[href="' + sessionStorage.getItem('action') + '"]').get(0).click();
        sessionStorage.clear();
    }

    // video check
    var container = $('.video-container');
    if (container.length > 0) {
      // we hide the play button, while YouTube API is not available
      container.each(function(element){
        var imageSrc = $('img', this).attr('src');
        var href = $('a', this).attr('href');
        var content = '<img src="' + imageSrc + '" alt="" class="img-responsive" /> \
                       <a class="play-button" aria-label="Play" href="' + href + '" target="_blank" data-type="video"></a>';
        
        if (typeof href != 'undefined'){
            $(this).html(content);
            $('.play-button', this).addClass('invisible');
        }
      });
      // load YouTube API asynchronously
      var tag = document.createElement('script');

      tag.src = "https://www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    // MEDIA QUERIES STUFF
    // this needs to be done programmatically because we need to set the border-width
    // and because we can't use percentages and this is responsive ... we need to
    // adjust the width accordingly
    if (window.matchMedia) {
        var mq = window.matchMedia('(max-width: 991px)');
        mq.addListener(handleMedia);
        handleMedia(mq);
    }

    function handleMedia(mq) {
        if (mq.matches) {
            // bind resize so that we can resize arrow accordingly
            $(window).resize(resizeArrow);
            resizeArrow();
        } else {
            // just remove the listener when we don't need it
            $(window).off('resize');
        }
    }

    function resizeArrow() {
        var flowStep = $('.flow-steps'),
            width = flowStep.width() / 2;

        $('.step-arrow')
            .css('border-left-width', width + 'px')
            .css('border-right-width', width + 'px');
    }
});

function teacherGateway(event, altLogin) {
    if (window.teacher) return true;

    var view = '/auth/login';
    // check which view we should use
    if (altLogin || altLogin === undefined) {
        view = '/auth/login2';
    }

    // prevent default event
    event.stopImmediatePropagation();
    event.preventDefault();
    // show modal
    $('#myLogin').modal({
        remote: view
    });

    // check if sessionStorage and store the href of the element
    if (window.sessionStorage) {
      sessionStorage.setItem('action', $(event.currentTarget).attr('href'));
    }

    return false;
}

function extractLocation(element) {
  return this.tagName === 'A' ? this.href : $(this).data('link');
}

function filenameFromElement(element) {
  // here we check if it's an anchor or another element
  // to get filename we do a split by "/" and get the last element
  // from the array
  var path = element.tagName === 'A' ? element.href : $(element).data('file');
  return path.split('/').pop();
}

function analyticsEvent(target, type, value) {
    // just check if analytics is available first!
    if (window.ga) {
        ga('send', 'event', target, type, value);
    }
}

// REVIEW: not the prettiest thing in the universe ... but for now
// this will do
function loginValidation() {
    var icon = "<i class='fa fa-times-circle'></i>";
    $("#login_form").validate({
        rules: {
            email: {
                required: true,
                email: true
            },
            password: {
                required: true
            }
        },
        messages: {
            email: {
                required: icon + "<span>You need to enter your e-mail.</span>",
                email: icon + "<span>You have not entered a valid email.</span>"
            },
            password: {
                required: icon + "<span>You need to enter your password.</span>"
            }
        },
        errorClass: 'form_error_messages'
    });

    $('#login_form input').on('change', function() {
        if ($(this).valid()) {
            // on success remove any has-error class
            $('#login_form .has-error').removeClass('has-error');
            // remove error message
            $('#login_form .form-error').remove();
        }
    });
}

function resetValidation() {
    var icon = "<i class='fa fa-times-circle'></i>";
    $("#reset_form").validate({
        rules: {
            email: {
                required: true,
                email: true
            }
        },
        messages: {
            email: {
                required: icon + "<span>You need to enter your e-mail.<span>",
                email: icon + "<span>You have not entered a valid email.</span>"
            }
        },
        errorClass: 'form_error_messages',
        invalidHandler: function () {
        }
    });
}

function onYouTubeIframeAPIReady() {
    console.log($('.video-container'));
  $('.video-container .play-button').removeClass('invisible');
  // handle click
  $('.video-container .play-button').on('click', function(event) {
    event.preventDefault();
    var element = $(this),
        target = element.parent(),
        paramsList = {},
        parameters = this.search.slice(1); // to remove ?

    // parameters as key => value
    $(parameters.split('&')).each(function(index, element) {
        element = element.split('=');
        paramsList[element[0]] = element[1];
    });

    // initialize player
    var player = new YT.Player(target.get(0), {
        width: target.width(),
        height: target.height(),
        playerVars: { autoplay: 1, rel: 0, modestbranding: 1, showinfo: 0 },
        videoId: paramsList.v
    });
  });
}
